<template>
  <layout :loading="loading">
    <div class="table-page">
      <div class="table-page__header">
        <h1 class="title-1">API Keys</h1>
        <b-button
          variant="success"
          @click="onAddButtonClick"
        >
          Add API Key
        </b-button>
      </div>
      <b-card class="table-page__container">
        <b-table
          :items="list"
          :fields="fields"
          hover
          sticky-header
          class="app-table"
        >
          <template #cell(employees)="{ value: employees, index }">
            <div
              v-for="employee in employees"
              :key="`${index}-${employee.uid}`"
            >
              {{ employee.name }}
            </div>
          </template>
          <template #cell(api_key)="{ value }">
            {{ value.length > 4 ? `${value.slice(0, 4)}...` : value }}
          </template>
          <template #cell(actions)="{ item }">
            <div class="d-flex align-items-center justify-content-end">
              <i
                class="bx bx-pencil text-warning font-size-20 mr-3 cursor-pointer"
                @click="onEditIconClick(item)"
              />
              <i
                class="bx bx-trash text-danger font-size-20 cursor-pointer"
                @click="onDeleteIconClick(item.id)"
              />
            </div>
          </template>
        </b-table>
      </b-card>
    </div>
    <api-keys-form-modal
      v-model="isModalVisible"
      :initial-data="editableKey"
      @hide="onModalHide"
      @submit-success="onSubmitSuccess"
    />
  </layout>
</template>

<script>
import Layout from '@/router/layouts/main.vue';
import apiKeysMixin from '@/mixins/api-keys';
import ApiKeysFormModal from '@/components/api-keys/api-keys-form-modal.vue';

export default {
  components: {
    Layout,
    ApiKeysFormModal
  },
  mixins: [apiKeysMixin],
  async mounted() {
    const apiKeyField = this.fields.find(field => field.key === 'api_key');
    console.log(apiKeyField);
    apiKeyField.thStyle = { minWidth: '100px', width: '100px', maxWidth: '100px' };
    this.loading = true;
    await this.$store.dispatch('apiKeys/getApiKeys');
    this.loading = false;
  },
  methods: {
    async fetchList() {
      await this.$store.dispatch('apiKeys/getApiKeys');
    }
  },
  computed: {
    list() {
      return this.$store.state.apiKeys.list;
    }
  }
}
</script>
